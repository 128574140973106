
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "41247024-46f4-4a35-844a-b3251c49bc54"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/app/apps/core/app.config.ts"
import cfg1 from "/app/apps/legacy/app.config.ts"
import cfg2 from "/app/apps/core/app.config.ts"
import cfg3 from "/app/apps/mojposaohr/app.config.ts"
import cfg4 from "/app/apps/core/app.config.ts"
import cfg5 from "/app/apps/legacy/app.config.ts"
import cfg6 from "/app/apps/core/app.config.ts"
import cfg7 from "/app/apps/articles/app.config.ts"
import cfg8 from "/app/apps/core/app.config.ts"
import cfg9 from "/app/apps/employers/app.config.ts"
import cfg10 from "/app/apps/core/app.config.ts"
import cfg11 from "/app/apps/generic/app.config.ts"
import cfg12 from "/app/apps/core/app.config.ts"
import cfg13 from "/app/apps/legacy/app.config.ts"
import cfg14 from "/app/apps/core/app.config.ts"
import cfg15 from "/app/apps/articles/app.config.ts"
import cfg16 from "/app/apps/core/app.config.ts"
import cfg17 from "/app/apps/jobs/app.config.ts"
import cfg18 from "/app/apps/core/app.config.ts"
import cfg19 from "/app/apps/generic/app.config.ts"
import cfg20 from "/app/apps/core/app.config.ts"
import cfg21 from "/app/apps/legacy/app.config.ts"
import cfg22 from "/app/apps/core/app.config.ts"
import cfg23 from "/app/apps/generic/app.config.ts"
import cfg24 from "/app/apps/core/app.config.ts"
import cfg25 from "/app/apps/legacy/app.config.ts"
import cfg26 from "/app/apps/core/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, cfg5, cfg6, cfg7, cfg8, cfg9, cfg10, cfg11, cfg12, cfg13, cfg14, cfg15, cfg16, cfg17, cfg18, cfg19, cfg20, cfg21, cfg22, cfg23, cfg24, cfg25, cfg26, inlineConfig)
