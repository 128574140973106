import { default as _91slug_93DkvhLhO6BlMeta } from "/app/apps/articles/pages/advices/[adviceId]/[slug].vue?macro=true";
import { default as indexqoUofFD8LpMeta } from "/app/apps/articles/pages/advices/index.vue?macro=true";
import { default as _91topicId_93wQ32vbvyhAMeta } from "/app/apps/articles/pages/advices/topics/[topicId].vue?macro=true";
import { default as verificationxxVClvGANLMeta } from "/app/apps/site/layers/Job/pages/application/verification.vue?macro=true";
import { default as forgotten_45passwordRpZVzXqozwMeta } from "/app/apps/site/pages/auth/forgotten-password.vue?macro=true";
import { default as indexvVp5q835c9Meta } from "/app/apps/site/pages/auth/index.vue?macro=true";
import { default as login9aL5YFykZgMeta } from "/app/apps/site/pages/auth/login.vue?macro=true";
import { default as logout3ToXs4iiNrMeta } from "/app/apps/site/pages/auth/logout.vue?macro=true";
import { default as verify_45emailRvb47T6gzOMeta } from "/app/apps/site/pages/auth/verify-email.vue?macro=true";
import { default as verifyCovoxa0j2NMeta } from "/app/apps/site/pages/auth/verify.vue?macro=true";
import { default as authorJak2L365A0Meta } from "/app/apps/site/pages/calculator/author.vue?macro=true";
import { default as salaryTyS2Dt5Jx6Meta } from "/app/apps/site/pages/calculator/salary.vue?macro=true";
import { default as serviceEkzLfqk1G4Meta } from "/app/apps/site/pages/calculator/service.vue?macro=true";
import { default as configkj7mpiY1EoMeta } from "/app/apps/site/pages/debug/config.vue?macro=true";
import { default as routesSQU0r3BH8UMeta } from "/app/apps/site/pages/debug/routes.vue?macro=true";
import { default as _91employerId_93oEMx6XXYW5Meta } from "/app/apps/employers/pages/employers/[employerId].vue?macro=true";
import { default as index5nNzzZj2XKMeta } from "/app/apps/employers/pages/employers/index.vue?macro=true";
import { default as _91category_93S43E2l0ypAMeta } from "/app/apps/site/pages/faq/[category].vue?macro=true";
import { default as indexyh7btIRDb0Meta } from "/app/apps/site/pages/faq/index.vue?macro=true";
import { default as indexhfvhpQJmdsMeta } from "/app/apps/site/pages/index/index.vue?macro=true";
import { default as _91slug_93WUsuQsVLwcMeta } from "/app/apps/site/pages/info/[slug].vue?macro=true";
import { default as apply_45external_45countdowno4dScsCepgMeta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue?macro=true";
import { default as apply_45external_45formffRxqQBZErMeta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue?macro=true";
import { default as apply_45externalZSWdh18JwnMeta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue?macro=true";
import { default as applyckcqptWrm5Meta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue?macro=true";
import { default as detailsUwtPC3FJB9Meta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue?macro=true";
import { default as _91jobId_93r9pWVUJYhIMeta } from "/app/apps/site/layers/Job/pages/job/[jobId].vue?macro=true";
import { default as _91slug_930wDRPfkTTpMeta } from "/app/apps/jobs/pages/jobs/[slug].vue?macro=true";
import { default as indexMZAUHAfz2hMeta } from "/app/apps/jobs/pages/jobs/index.vue?macro=true";
import { default as my_45profile_45preview09icPF9X4kMeta } from "/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue?macro=true";
import { default as my_45profileOqnUgpRMHiMeta } from "/app/apps/site/layers/MyProfile/pages/my-profile.vue?macro=true";
import { default as applicationsSeLeQ1SiZOMeta } from "/app/apps/site/pages/my/applications.vue?macro=true";
import { default as documentspLgNA6kZOSMeta } from "/app/apps/site/pages/my/documents.vue?macro=true";
import { default as favorite_45jobstsEDq5mqXaMeta } from "/app/apps/site/pages/my/favorite-jobs.vue?macro=true";
import { default as notificationsCc6VMGb4P0Meta } from "/app/apps/site/pages/my/notifications.vue?macro=true";
import { default as settingsLPMr7BiiGHMeta } from "/app/apps/site/pages/my/settings.vue?macro=true";
import { default as indexFlTcufSab2Meta } from "/app/apps/site/pages/search/index.vue?macro=true";
export default [
  {
    name: _91slug_93DkvhLhO6BlMeta?.name ?? "advices-adviceId-slug___hr",
    path: _91slug_93DkvhLhO6BlMeta?.path ?? "/savjet/:adviceId()/:slug()",
    meta: _91slug_93DkvhLhO6BlMeta || {},
    alias: _91slug_93DkvhLhO6BlMeta?.alias || [],
    redirect: _91slug_93DkvhLhO6BlMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/[adviceId]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DkvhLhO6BlMeta?.name ?? "advices-adviceId-slug___en",
    path: _91slug_93DkvhLhO6BlMeta?.path ?? "/en/advice/:adviceId()/:slug()",
    meta: _91slug_93DkvhLhO6BlMeta || {},
    alias: _91slug_93DkvhLhO6BlMeta?.alias || [],
    redirect: _91slug_93DkvhLhO6BlMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/[adviceId]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DkvhLhO6BlMeta?.name ?? "advices-adviceId-slug___sl",
    path: _91slug_93DkvhLhO6BlMeta?.path ?? "/sl/savjet/:adviceId()/:slug()",
    meta: _91slug_93DkvhLhO6BlMeta || {},
    alias: _91slug_93DkvhLhO6BlMeta?.alias || [],
    redirect: _91slug_93DkvhLhO6BlMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/[adviceId]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DkvhLhO6BlMeta?.name ?? "advices-adviceId-slug___bs",
    path: _91slug_93DkvhLhO6BlMeta?.path ?? "/bs/savjet/:adviceId()/:slug()",
    meta: _91slug_93DkvhLhO6BlMeta || {},
    alias: _91slug_93DkvhLhO6BlMeta?.alias || [],
    redirect: _91slug_93DkvhLhO6BlMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/[adviceId]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DkvhLhO6BlMeta?.name ?? "advices-adviceId-slug___mk",
    path: _91slug_93DkvhLhO6BlMeta?.path ?? "/mk/savjet/:adviceId()/:slug()",
    meta: _91slug_93DkvhLhO6BlMeta || {},
    alias: _91slug_93DkvhLhO6BlMeta?.alias || [],
    redirect: _91slug_93DkvhLhO6BlMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/[adviceId]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexqoUofFD8LpMeta?.name ?? "advices___hr",
    path: indexqoUofFD8LpMeta?.path ?? "/savjeti",
    meta: indexqoUofFD8LpMeta || {},
    alias: indexqoUofFD8LpMeta?.alias || [],
    redirect: indexqoUofFD8LpMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/index.vue").then(m => m.default || m)
  },
  {
    name: indexqoUofFD8LpMeta?.name ?? "advices___en",
    path: indexqoUofFD8LpMeta?.path ?? "/en/advices",
    meta: indexqoUofFD8LpMeta || {},
    alias: indexqoUofFD8LpMeta?.alias || [],
    redirect: indexqoUofFD8LpMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/index.vue").then(m => m.default || m)
  },
  {
    name: indexqoUofFD8LpMeta?.name ?? "advices___sl",
    path: indexqoUofFD8LpMeta?.path ?? "/sl/savjeti",
    meta: indexqoUofFD8LpMeta || {},
    alias: indexqoUofFD8LpMeta?.alias || [],
    redirect: indexqoUofFD8LpMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/index.vue").then(m => m.default || m)
  },
  {
    name: indexqoUofFD8LpMeta?.name ?? "advices___bs",
    path: indexqoUofFD8LpMeta?.path ?? "/bs/savjeti",
    meta: indexqoUofFD8LpMeta || {},
    alias: indexqoUofFD8LpMeta?.alias || [],
    redirect: indexqoUofFD8LpMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/index.vue").then(m => m.default || m)
  },
  {
    name: indexqoUofFD8LpMeta?.name ?? "advices___mk",
    path: indexqoUofFD8LpMeta?.path ?? "/mk/savjeti",
    meta: indexqoUofFD8LpMeta || {},
    alias: indexqoUofFD8LpMeta?.alias || [],
    redirect: indexqoUofFD8LpMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/index.vue").then(m => m.default || m)
  },
  {
    name: _91topicId_93wQ32vbvyhAMeta?.name ?? "advices-topics-topicId___hr",
    path: _91topicId_93wQ32vbvyhAMeta?.path ?? "/savjeti/tema/:topicId()",
    meta: _91topicId_93wQ32vbvyhAMeta || {},
    alias: _91topicId_93wQ32vbvyhAMeta?.alias || [],
    redirect: _91topicId_93wQ32vbvyhAMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/topics/[topicId].vue").then(m => m.default || m)
  },
  {
    name: _91topicId_93wQ32vbvyhAMeta?.name ?? "advices-topics-topicId___en",
    path: _91topicId_93wQ32vbvyhAMeta?.path ?? "/en/advices/topic/:topicId()",
    meta: _91topicId_93wQ32vbvyhAMeta || {},
    alias: _91topicId_93wQ32vbvyhAMeta?.alias || [],
    redirect: _91topicId_93wQ32vbvyhAMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/topics/[topicId].vue").then(m => m.default || m)
  },
  {
    name: _91topicId_93wQ32vbvyhAMeta?.name ?? "advices-topics-topicId___sl",
    path: _91topicId_93wQ32vbvyhAMeta?.path ?? "/sl/savjeti/tema/:topicId()",
    meta: _91topicId_93wQ32vbvyhAMeta || {},
    alias: _91topicId_93wQ32vbvyhAMeta?.alias || [],
    redirect: _91topicId_93wQ32vbvyhAMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/topics/[topicId].vue").then(m => m.default || m)
  },
  {
    name: _91topicId_93wQ32vbvyhAMeta?.name ?? "advices-topics-topicId___bs",
    path: _91topicId_93wQ32vbvyhAMeta?.path ?? "/bs/savjeti/tema/:topicId()",
    meta: _91topicId_93wQ32vbvyhAMeta || {},
    alias: _91topicId_93wQ32vbvyhAMeta?.alias || [],
    redirect: _91topicId_93wQ32vbvyhAMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/topics/[topicId].vue").then(m => m.default || m)
  },
  {
    name: _91topicId_93wQ32vbvyhAMeta?.name ?? "advices-topics-topicId___mk",
    path: _91topicId_93wQ32vbvyhAMeta?.path ?? "/mk/savjeti/tema/:topicId()",
    meta: _91topicId_93wQ32vbvyhAMeta || {},
    alias: _91topicId_93wQ32vbvyhAMeta?.alias || [],
    redirect: _91topicId_93wQ32vbvyhAMeta?.redirect,
    component: () => import("/app/apps/articles/pages/advices/topics/[topicId].vue").then(m => m.default || m)
  },
  {
    name: verificationxxVClvGANLMeta?.name ?? "application-verification___hr",
    path: verificationxxVClvGANLMeta?.path ?? "/application/verification",
    meta: verificationxxVClvGANLMeta || {},
    alias: verificationxxVClvGANLMeta?.alias || [],
    redirect: verificationxxVClvGANLMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationxxVClvGANLMeta?.name ?? "application-verification___en",
    path: verificationxxVClvGANLMeta?.path ?? "/en/application/verification",
    meta: verificationxxVClvGANLMeta || {},
    alias: verificationxxVClvGANLMeta?.alias || [],
    redirect: verificationxxVClvGANLMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationxxVClvGANLMeta?.name ?? "application-verification___sl",
    path: verificationxxVClvGANLMeta?.path ?? "/sl/application/verification",
    meta: verificationxxVClvGANLMeta || {},
    alias: verificationxxVClvGANLMeta?.alias || [],
    redirect: verificationxxVClvGANLMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationxxVClvGANLMeta?.name ?? "application-verification___bs",
    path: verificationxxVClvGANLMeta?.path ?? "/bs/application/verification",
    meta: verificationxxVClvGANLMeta || {},
    alias: verificationxxVClvGANLMeta?.alias || [],
    redirect: verificationxxVClvGANLMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue").then(m => m.default || m)
  },
  {
    name: verificationxxVClvGANLMeta?.name ?? "application-verification___mk",
    path: verificationxxVClvGANLMeta?.path ?? "/mk/application/verification",
    meta: verificationxxVClvGANLMeta || {},
    alias: verificationxxVClvGANLMeta?.alias || [],
    redirect: verificationxxVClvGANLMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue").then(m => m.default || m)
  },
  {
    name: forgotten_45passwordRpZVzXqozwMeta?.name ?? "auth-forgotten-password___hr",
    path: forgotten_45passwordRpZVzXqozwMeta?.path ?? "/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    alias: forgotten_45passwordRpZVzXqozwMeta?.alias || [],
    redirect: forgotten_45passwordRpZVzXqozwMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: forgotten_45passwordRpZVzXqozwMeta?.name ?? "auth-forgotten-password___en",
    path: forgotten_45passwordRpZVzXqozwMeta?.path ?? "/en/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    alias: forgotten_45passwordRpZVzXqozwMeta?.alias || [],
    redirect: forgotten_45passwordRpZVzXqozwMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: forgotten_45passwordRpZVzXqozwMeta?.name ?? "auth-forgotten-password___sl",
    path: forgotten_45passwordRpZVzXqozwMeta?.path ?? "/sl/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    alias: forgotten_45passwordRpZVzXqozwMeta?.alias || [],
    redirect: forgotten_45passwordRpZVzXqozwMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: forgotten_45passwordRpZVzXqozwMeta?.name ?? "auth-forgotten-password___bs",
    path: forgotten_45passwordRpZVzXqozwMeta?.path ?? "/bs/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    alias: forgotten_45passwordRpZVzXqozwMeta?.alias || [],
    redirect: forgotten_45passwordRpZVzXqozwMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: forgotten_45passwordRpZVzXqozwMeta?.name ?? "auth-forgotten-password___mk",
    path: forgotten_45passwordRpZVzXqozwMeta?.path ?? "/mk/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    alias: forgotten_45passwordRpZVzXqozwMeta?.alias || [],
    redirect: forgotten_45passwordRpZVzXqozwMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue").then(m => m.default || m)
  },
  {
    name: indexvVp5q835c9Meta?.name ?? "auth___hr",
    path: indexvVp5q835c9Meta?.path ?? "/auth",
    meta: indexvVp5q835c9Meta || {},
    alias: indexvVp5q835c9Meta?.alias || [],
    redirect: indexvVp5q835c9Meta?.redirect,
    component: () => import("/app/apps/site/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexvVp5q835c9Meta?.name ?? "auth___en",
    path: indexvVp5q835c9Meta?.path ?? "/en/auth",
    meta: indexvVp5q835c9Meta || {},
    alias: indexvVp5q835c9Meta?.alias || [],
    redirect: indexvVp5q835c9Meta?.redirect,
    component: () => import("/app/apps/site/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexvVp5q835c9Meta?.name ?? "auth___sl",
    path: indexvVp5q835c9Meta?.path ?? "/sl/auth",
    meta: indexvVp5q835c9Meta || {},
    alias: indexvVp5q835c9Meta?.alias || [],
    redirect: indexvVp5q835c9Meta?.redirect,
    component: () => import("/app/apps/site/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexvVp5q835c9Meta?.name ?? "auth___bs",
    path: indexvVp5q835c9Meta?.path ?? "/bs/auth",
    meta: indexvVp5q835c9Meta || {},
    alias: indexvVp5q835c9Meta?.alias || [],
    redirect: indexvVp5q835c9Meta?.redirect,
    component: () => import("/app/apps/site/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexvVp5q835c9Meta?.name ?? "auth___mk",
    path: indexvVp5q835c9Meta?.path ?? "/mk/auth",
    meta: indexvVp5q835c9Meta || {},
    alias: indexvVp5q835c9Meta?.alias || [],
    redirect: indexvVp5q835c9Meta?.redirect,
    component: () => import("/app/apps/site/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: login9aL5YFykZgMeta?.name ?? "auth-login___hr",
    path: login9aL5YFykZgMeta?.path ?? "/prijava",
    meta: login9aL5YFykZgMeta || {},
    alias: login9aL5YFykZgMeta?.alias || [],
    redirect: login9aL5YFykZgMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: login9aL5YFykZgMeta?.name ?? "auth-login___en",
    path: login9aL5YFykZgMeta?.path ?? "/en/login",
    meta: login9aL5YFykZgMeta || {},
    alias: login9aL5YFykZgMeta?.alias || [],
    redirect: login9aL5YFykZgMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: login9aL5YFykZgMeta?.name ?? "auth-login___sl",
    path: login9aL5YFykZgMeta?.path ?? "/sl/prijava",
    meta: login9aL5YFykZgMeta || {},
    alias: login9aL5YFykZgMeta?.alias || [],
    redirect: login9aL5YFykZgMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: login9aL5YFykZgMeta?.name ?? "auth-login___bs",
    path: login9aL5YFykZgMeta?.path ?? "/bs/prijava",
    meta: login9aL5YFykZgMeta || {},
    alias: login9aL5YFykZgMeta?.alias || [],
    redirect: login9aL5YFykZgMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: login9aL5YFykZgMeta?.name ?? "auth-login___mk",
    path: login9aL5YFykZgMeta?.path ?? "/mk/prijava",
    meta: login9aL5YFykZgMeta || {},
    alias: login9aL5YFykZgMeta?.alias || [],
    redirect: login9aL5YFykZgMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: logout3ToXs4iiNrMeta?.name ?? "auth-logout___hr",
    path: logout3ToXs4iiNrMeta?.path ?? "/odjava",
    meta: logout3ToXs4iiNrMeta || {},
    alias: logout3ToXs4iiNrMeta?.alias || [],
    redirect: logout3ToXs4iiNrMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: logout3ToXs4iiNrMeta?.name ?? "auth-logout___en",
    path: logout3ToXs4iiNrMeta?.path ?? "/en/logout",
    meta: logout3ToXs4iiNrMeta || {},
    alias: logout3ToXs4iiNrMeta?.alias || [],
    redirect: logout3ToXs4iiNrMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: logout3ToXs4iiNrMeta?.name ?? "auth-logout___sl",
    path: logout3ToXs4iiNrMeta?.path ?? "/sl/odjava",
    meta: logout3ToXs4iiNrMeta || {},
    alias: logout3ToXs4iiNrMeta?.alias || [],
    redirect: logout3ToXs4iiNrMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: logout3ToXs4iiNrMeta?.name ?? "auth-logout___bs",
    path: logout3ToXs4iiNrMeta?.path ?? "/bs/odjava",
    meta: logout3ToXs4iiNrMeta || {},
    alias: logout3ToXs4iiNrMeta?.alias || [],
    redirect: logout3ToXs4iiNrMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: logout3ToXs4iiNrMeta?.name ?? "auth-logout___mk",
    path: logout3ToXs4iiNrMeta?.path ?? "/mk/odjava",
    meta: logout3ToXs4iiNrMeta || {},
    alias: logout3ToXs4iiNrMeta?.alias || [],
    redirect: logout3ToXs4iiNrMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailRvb47T6gzOMeta?.name ?? "auth-verify-email___hr",
    path: verify_45emailRvb47T6gzOMeta?.path ?? "/auth/verify-email",
    meta: verify_45emailRvb47T6gzOMeta || {},
    alias: verify_45emailRvb47T6gzOMeta?.alias || [],
    redirect: verify_45emailRvb47T6gzOMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailRvb47T6gzOMeta?.name ?? "auth-verify-email___en",
    path: verify_45emailRvb47T6gzOMeta?.path ?? "/en/auth/verify-email",
    meta: verify_45emailRvb47T6gzOMeta || {},
    alias: verify_45emailRvb47T6gzOMeta?.alias || [],
    redirect: verify_45emailRvb47T6gzOMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailRvb47T6gzOMeta?.name ?? "auth-verify-email___sl",
    path: verify_45emailRvb47T6gzOMeta?.path ?? "/sl/auth/verify-email",
    meta: verify_45emailRvb47T6gzOMeta || {},
    alias: verify_45emailRvb47T6gzOMeta?.alias || [],
    redirect: verify_45emailRvb47T6gzOMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailRvb47T6gzOMeta?.name ?? "auth-verify-email___bs",
    path: verify_45emailRvb47T6gzOMeta?.path ?? "/bs/auth/verify-email",
    meta: verify_45emailRvb47T6gzOMeta || {},
    alias: verify_45emailRvb47T6gzOMeta?.alias || [],
    redirect: verify_45emailRvb47T6gzOMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailRvb47T6gzOMeta?.name ?? "auth-verify-email___mk",
    path: verify_45emailRvb47T6gzOMeta?.path ?? "/mk/auth/verify-email",
    meta: verify_45emailRvb47T6gzOMeta || {},
    alias: verify_45emailRvb47T6gzOMeta?.alias || [],
    redirect: verify_45emailRvb47T6gzOMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: verifyCovoxa0j2NMeta?.name ?? "auth-verify___hr",
    path: verifyCovoxa0j2NMeta?.path ?? "/auth/verify",
    meta: verifyCovoxa0j2NMeta || {},
    alias: verifyCovoxa0j2NMeta?.alias || [],
    redirect: verifyCovoxa0j2NMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCovoxa0j2NMeta?.name ?? "auth-verify___en",
    path: verifyCovoxa0j2NMeta?.path ?? "/en/auth/verify",
    meta: verifyCovoxa0j2NMeta || {},
    alias: verifyCovoxa0j2NMeta?.alias || [],
    redirect: verifyCovoxa0j2NMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCovoxa0j2NMeta?.name ?? "auth-verify___sl",
    path: verifyCovoxa0j2NMeta?.path ?? "/sl/auth/verify",
    meta: verifyCovoxa0j2NMeta || {},
    alias: verifyCovoxa0j2NMeta?.alias || [],
    redirect: verifyCovoxa0j2NMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCovoxa0j2NMeta?.name ?? "auth-verify___bs",
    path: verifyCovoxa0j2NMeta?.path ?? "/bs/auth/verify",
    meta: verifyCovoxa0j2NMeta || {},
    alias: verifyCovoxa0j2NMeta?.alias || [],
    redirect: verifyCovoxa0j2NMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyCovoxa0j2NMeta?.name ?? "auth-verify___mk",
    path: verifyCovoxa0j2NMeta?.path ?? "/mk/auth/verify",
    meta: verifyCovoxa0j2NMeta || {},
    alias: verifyCovoxa0j2NMeta?.alias || [],
    redirect: verifyCovoxa0j2NMeta?.redirect,
    component: () => import("/app/apps/site/pages/auth/verify.vue").then(m => m.default || m)
  },
  {
    name: authorJak2L365A0Meta?.name ?? "calculator-author___hr",
    path: authorJak2L365A0Meta?.path ?? "/kalkulator/autorski-ugovor",
    meta: authorJak2L365A0Meta || {},
    alias: authorJak2L365A0Meta?.alias || [],
    redirect: authorJak2L365A0Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/author.vue").then(m => m.default || m)
  },
  {
    name: authorJak2L365A0Meta?.name ?? "calculator-author___en",
    path: authorJak2L365A0Meta?.path ?? "/en/calculator/royalty-contract",
    meta: authorJak2L365A0Meta || {},
    alias: authorJak2L365A0Meta?.alias || [],
    redirect: authorJak2L365A0Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/author.vue").then(m => m.default || m)
  },
  {
    name: authorJak2L365A0Meta?.name ?? "calculator-author___sl",
    path: authorJak2L365A0Meta?.path ?? "/sl/kalkulator/autorski-ugovor",
    meta: authorJak2L365A0Meta || {},
    alias: authorJak2L365A0Meta?.alias || [],
    redirect: authorJak2L365A0Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/author.vue").then(m => m.default || m)
  },
  {
    name: authorJak2L365A0Meta?.name ?? "calculator-author___bs",
    path: authorJak2L365A0Meta?.path ?? "/bs/kalkulator/autorski-ugovor",
    meta: authorJak2L365A0Meta || {},
    alias: authorJak2L365A0Meta?.alias || [],
    redirect: authorJak2L365A0Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/author.vue").then(m => m.default || m)
  },
  {
    name: authorJak2L365A0Meta?.name ?? "calculator-author___mk",
    path: authorJak2L365A0Meta?.path ?? "/mk/kalkulator/autorski-ugovor",
    meta: authorJak2L365A0Meta || {},
    alias: authorJak2L365A0Meta?.alias || [],
    redirect: authorJak2L365A0Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/author.vue").then(m => m.default || m)
  },
  {
    name: salaryTyS2Dt5Jx6Meta?.name ?? "calculator-salary___hr",
    path: salaryTyS2Dt5Jx6Meta?.path ?? "/kalkulator/izracun-place",
    meta: salaryTyS2Dt5Jx6Meta || {},
    alias: salaryTyS2Dt5Jx6Meta?.alias || [],
    redirect: salaryTyS2Dt5Jx6Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/salary.vue").then(m => m.default || m)
  },
  {
    name: salaryTyS2Dt5Jx6Meta?.name ?? "calculator-salary___en",
    path: salaryTyS2Dt5Jx6Meta?.path ?? "/en/calculator/salary",
    meta: salaryTyS2Dt5Jx6Meta || {},
    alias: salaryTyS2Dt5Jx6Meta?.alias || [],
    redirect: salaryTyS2Dt5Jx6Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/salary.vue").then(m => m.default || m)
  },
  {
    name: salaryTyS2Dt5Jx6Meta?.name ?? "calculator-salary___sl",
    path: salaryTyS2Dt5Jx6Meta?.path ?? "/sl/kalkulator/izracun-place",
    meta: salaryTyS2Dt5Jx6Meta || {},
    alias: salaryTyS2Dt5Jx6Meta?.alias || [],
    redirect: salaryTyS2Dt5Jx6Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/salary.vue").then(m => m.default || m)
  },
  {
    name: salaryTyS2Dt5Jx6Meta?.name ?? "calculator-salary___bs",
    path: salaryTyS2Dt5Jx6Meta?.path ?? "/bs/kalkulator/izracun-place",
    meta: salaryTyS2Dt5Jx6Meta || {},
    alias: salaryTyS2Dt5Jx6Meta?.alias || [],
    redirect: salaryTyS2Dt5Jx6Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/salary.vue").then(m => m.default || m)
  },
  {
    name: salaryTyS2Dt5Jx6Meta?.name ?? "calculator-salary___mk",
    path: salaryTyS2Dt5Jx6Meta?.path ?? "/mk/kalkulator/izracun-place",
    meta: salaryTyS2Dt5Jx6Meta || {},
    alias: salaryTyS2Dt5Jx6Meta?.alias || [],
    redirect: salaryTyS2Dt5Jx6Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/salary.vue").then(m => m.default || m)
  },
  {
    name: serviceEkzLfqk1G4Meta?.name ?? "calculator-service___hr",
    path: serviceEkzLfqk1G4Meta?.path ?? "/kalkulator/ugovor-o-djelu",
    meta: serviceEkzLfqk1G4Meta || {},
    alias: serviceEkzLfqk1G4Meta?.alias || [],
    redirect: serviceEkzLfqk1G4Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/service.vue").then(m => m.default || m)
  },
  {
    name: serviceEkzLfqk1G4Meta?.name ?? "calculator-service___en",
    path: serviceEkzLfqk1G4Meta?.path ?? "/en/calculator/service-contract",
    meta: serviceEkzLfqk1G4Meta || {},
    alias: serviceEkzLfqk1G4Meta?.alias || [],
    redirect: serviceEkzLfqk1G4Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/service.vue").then(m => m.default || m)
  },
  {
    name: serviceEkzLfqk1G4Meta?.name ?? "calculator-service___sl",
    path: serviceEkzLfqk1G4Meta?.path ?? "/sl/kalkulator/ugovor-o-djelu",
    meta: serviceEkzLfqk1G4Meta || {},
    alias: serviceEkzLfqk1G4Meta?.alias || [],
    redirect: serviceEkzLfqk1G4Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/service.vue").then(m => m.default || m)
  },
  {
    name: serviceEkzLfqk1G4Meta?.name ?? "calculator-service___bs",
    path: serviceEkzLfqk1G4Meta?.path ?? "/bs/kalkulator/ugovor-o-djelu",
    meta: serviceEkzLfqk1G4Meta || {},
    alias: serviceEkzLfqk1G4Meta?.alias || [],
    redirect: serviceEkzLfqk1G4Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/service.vue").then(m => m.default || m)
  },
  {
    name: serviceEkzLfqk1G4Meta?.name ?? "calculator-service___mk",
    path: serviceEkzLfqk1G4Meta?.path ?? "/mk/kalkulator/ugovor-o-djelu",
    meta: serviceEkzLfqk1G4Meta || {},
    alias: serviceEkzLfqk1G4Meta?.alias || [],
    redirect: serviceEkzLfqk1G4Meta?.redirect,
    component: () => import("/app/apps/site/pages/calculator/service.vue").then(m => m.default || m)
  },
  {
    name: configkj7mpiY1EoMeta?.name ?? "debug-config___hr",
    path: configkj7mpiY1EoMeta?.path ?? "/debug/config",
    meta: configkj7mpiY1EoMeta || {},
    alias: configkj7mpiY1EoMeta?.alias || [],
    redirect: configkj7mpiY1EoMeta?.redirect,
    component: () => import("/app/apps/site/pages/debug/config.vue").then(m => m.default || m)
  },
  {
    name: configkj7mpiY1EoMeta?.name ?? "debug-config___en",
    path: configkj7mpiY1EoMeta?.path ?? "/en/debug/config",
    meta: configkj7mpiY1EoMeta || {},
    alias: configkj7mpiY1EoMeta?.alias || [],
    redirect: configkj7mpiY1EoMeta?.redirect,
    component: () => import("/app/apps/site/pages/debug/config.vue").then(m => m.default || m)
  },
  {
    name: configkj7mpiY1EoMeta?.name ?? "debug-config___sl",
    path: configkj7mpiY1EoMeta?.path ?? "/sl/debug/config",
    meta: configkj7mpiY1EoMeta || {},
    alias: configkj7mpiY1EoMeta?.alias || [],
    redirect: configkj7mpiY1EoMeta?.redirect,
    component: () => import("/app/apps/site/pages/debug/config.vue").then(m => m.default || m)
  },
  {
    name: configkj7mpiY1EoMeta?.name ?? "debug-config___bs",
    path: configkj7mpiY1EoMeta?.path ?? "/bs/debug/config",
    meta: configkj7mpiY1EoMeta || {},
    alias: configkj7mpiY1EoMeta?.alias || [],
    redirect: configkj7mpiY1EoMeta?.redirect,
    component: () => import("/app/apps/site/pages/debug/config.vue").then(m => m.default || m)
  },
  {
    name: configkj7mpiY1EoMeta?.name ?? "debug-config___mk",
    path: configkj7mpiY1EoMeta?.path ?? "/mk/debug/config",
    meta: configkj7mpiY1EoMeta || {},
    alias: configkj7mpiY1EoMeta?.alias || [],
    redirect: configkj7mpiY1EoMeta?.redirect,
    component: () => import("/app/apps/site/pages/debug/config.vue").then(m => m.default || m)
  },
  {
    name: routesSQU0r3BH8UMeta?.name ?? "debug-routes___hr",
    path: routesSQU0r3BH8UMeta?.path ?? "/debug/routes",
    meta: routesSQU0r3BH8UMeta || {},
    alias: routesSQU0r3BH8UMeta?.alias || [],
    redirect: routesSQU0r3BH8UMeta?.redirect,
    component: () => import("/app/apps/site/pages/debug/routes.vue").then(m => m.default || m)
  },
  {
    name: routesSQU0r3BH8UMeta?.name ?? "debug-routes___en",
    path: routesSQU0r3BH8UMeta?.path ?? "/en/debug/routes",
    meta: routesSQU0r3BH8UMeta || {},
    alias: routesSQU0r3BH8UMeta?.alias || [],
    redirect: routesSQU0r3BH8UMeta?.redirect,
    component: () => import("/app/apps/site/pages/debug/routes.vue").then(m => m.default || m)
  },
  {
    name: routesSQU0r3BH8UMeta?.name ?? "debug-routes___sl",
    path: routesSQU0r3BH8UMeta?.path ?? "/sl/debug/routes",
    meta: routesSQU0r3BH8UMeta || {},
    alias: routesSQU0r3BH8UMeta?.alias || [],
    redirect: routesSQU0r3BH8UMeta?.redirect,
    component: () => import("/app/apps/site/pages/debug/routes.vue").then(m => m.default || m)
  },
  {
    name: routesSQU0r3BH8UMeta?.name ?? "debug-routes___bs",
    path: routesSQU0r3BH8UMeta?.path ?? "/bs/debug/routes",
    meta: routesSQU0r3BH8UMeta || {},
    alias: routesSQU0r3BH8UMeta?.alias || [],
    redirect: routesSQU0r3BH8UMeta?.redirect,
    component: () => import("/app/apps/site/pages/debug/routes.vue").then(m => m.default || m)
  },
  {
    name: routesSQU0r3BH8UMeta?.name ?? "debug-routes___mk",
    path: routesSQU0r3BH8UMeta?.path ?? "/mk/debug/routes",
    meta: routesSQU0r3BH8UMeta || {},
    alias: routesSQU0r3BH8UMeta?.alias || [],
    redirect: routesSQU0r3BH8UMeta?.redirect,
    component: () => import("/app/apps/site/pages/debug/routes.vue").then(m => m.default || m)
  },
  {
    name: _91employerId_93oEMx6XXYW5Meta?.name ?? "employers-employerId___hr",
    path: _91employerId_93oEMx6XXYW5Meta?.path ?? "/poslodavac/:employerId()",
    meta: _91employerId_93oEMx6XXYW5Meta || {},
    alias: _91employerId_93oEMx6XXYW5Meta?.alias || [],
    redirect: _91employerId_93oEMx6XXYW5Meta?.redirect,
    component: () => import("/app/apps/employers/pages/employers/[employerId].vue").then(m => m.default || m)
  },
  {
    name: _91employerId_93oEMx6XXYW5Meta?.name ?? "employers-employerId___en",
    path: _91employerId_93oEMx6XXYW5Meta?.path ?? "/en/employer/:employerId()",
    meta: _91employerId_93oEMx6XXYW5Meta || {},
    alias: _91employerId_93oEMx6XXYW5Meta?.alias || [],
    redirect: _91employerId_93oEMx6XXYW5Meta?.redirect,
    component: () => import("/app/apps/employers/pages/employers/[employerId].vue").then(m => m.default || m)
  },
  {
    name: _91employerId_93oEMx6XXYW5Meta?.name ?? "employers-employerId___sl",
    path: _91employerId_93oEMx6XXYW5Meta?.path ?? "/sl/poslodavac/:employerId()",
    meta: _91employerId_93oEMx6XXYW5Meta || {},
    alias: _91employerId_93oEMx6XXYW5Meta?.alias || [],
    redirect: _91employerId_93oEMx6XXYW5Meta?.redirect,
    component: () => import("/app/apps/employers/pages/employers/[employerId].vue").then(m => m.default || m)
  },
  {
    name: _91employerId_93oEMx6XXYW5Meta?.name ?? "employers-employerId___bs",
    path: _91employerId_93oEMx6XXYW5Meta?.path ?? "/bs/poslodavac/:employerId()",
    meta: _91employerId_93oEMx6XXYW5Meta || {},
    alias: _91employerId_93oEMx6XXYW5Meta?.alias || [],
    redirect: _91employerId_93oEMx6XXYW5Meta?.redirect,
    component: () => import("/app/apps/employers/pages/employers/[employerId].vue").then(m => m.default || m)
  },
  {
    name: _91employerId_93oEMx6XXYW5Meta?.name ?? "employers-employerId___mk",
    path: _91employerId_93oEMx6XXYW5Meta?.path ?? "/mk/poslodavac/:employerId()",
    meta: _91employerId_93oEMx6XXYW5Meta || {},
    alias: _91employerId_93oEMx6XXYW5Meta?.alias || [],
    redirect: _91employerId_93oEMx6XXYW5Meta?.redirect,
    component: () => import("/app/apps/employers/pages/employers/[employerId].vue").then(m => m.default || m)
  },
  {
    name: index5nNzzZj2XKMeta?.name ?? "employers___hr",
    path: index5nNzzZj2XKMeta?.path ?? "/poslodavci",
    meta: index5nNzzZj2XKMeta || {},
    alias: index5nNzzZj2XKMeta?.alias || [],
    redirect: index5nNzzZj2XKMeta?.redirect,
    component: () => import("/app/apps/employers/pages/employers/index.vue").then(m => m.default || m)
  },
  {
    name: index5nNzzZj2XKMeta?.name ?? "employers___en",
    path: index5nNzzZj2XKMeta?.path ?? "/en/employers",
    meta: index5nNzzZj2XKMeta || {},
    alias: index5nNzzZj2XKMeta?.alias || [],
    redirect: index5nNzzZj2XKMeta?.redirect,
    component: () => import("/app/apps/employers/pages/employers/index.vue").then(m => m.default || m)
  },
  {
    name: index5nNzzZj2XKMeta?.name ?? "employers___sl",
    path: index5nNzzZj2XKMeta?.path ?? "/sl/poslodavci",
    meta: index5nNzzZj2XKMeta || {},
    alias: index5nNzzZj2XKMeta?.alias || [],
    redirect: index5nNzzZj2XKMeta?.redirect,
    component: () => import("/app/apps/employers/pages/employers/index.vue").then(m => m.default || m)
  },
  {
    name: index5nNzzZj2XKMeta?.name ?? "employers___bs",
    path: index5nNzzZj2XKMeta?.path ?? "/bs/poslodavci",
    meta: index5nNzzZj2XKMeta || {},
    alias: index5nNzzZj2XKMeta?.alias || [],
    redirect: index5nNzzZj2XKMeta?.redirect,
    component: () => import("/app/apps/employers/pages/employers/index.vue").then(m => m.default || m)
  },
  {
    name: index5nNzzZj2XKMeta?.name ?? "employers___mk",
    path: index5nNzzZj2XKMeta?.path ?? "/mk/poslodavci",
    meta: index5nNzzZj2XKMeta || {},
    alias: index5nNzzZj2XKMeta?.alias || [],
    redirect: index5nNzzZj2XKMeta?.redirect,
    component: () => import("/app/apps/employers/pages/employers/index.vue").then(m => m.default || m)
  },
  {
    name: _91category_93S43E2l0ypAMeta?.name ?? "faq-category___hr",
    path: _91category_93S43E2l0ypAMeta?.path ?? "/cesta-pitanja/:category()",
    meta: _91category_93S43E2l0ypAMeta || {},
    alias: _91category_93S43E2l0ypAMeta?.alias || [],
    redirect: _91category_93S43E2l0ypAMeta?.redirect,
    component: () => import("/app/apps/site/pages/faq/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93S43E2l0ypAMeta?.name ?? "faq-category___en",
    path: _91category_93S43E2l0ypAMeta?.path ?? "/en/faq/:category()",
    meta: _91category_93S43E2l0ypAMeta || {},
    alias: _91category_93S43E2l0ypAMeta?.alias || [],
    redirect: _91category_93S43E2l0ypAMeta?.redirect,
    component: () => import("/app/apps/site/pages/faq/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93S43E2l0ypAMeta?.name ?? "faq-category___sl",
    path: _91category_93S43E2l0ypAMeta?.path ?? "/sl/cesta-pitanja/:category()",
    meta: _91category_93S43E2l0ypAMeta || {},
    alias: _91category_93S43E2l0ypAMeta?.alias || [],
    redirect: _91category_93S43E2l0ypAMeta?.redirect,
    component: () => import("/app/apps/site/pages/faq/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93S43E2l0ypAMeta?.name ?? "faq-category___bs",
    path: _91category_93S43E2l0ypAMeta?.path ?? "/bs/cesta-pitanja/:category()",
    meta: _91category_93S43E2l0ypAMeta || {},
    alias: _91category_93S43E2l0ypAMeta?.alias || [],
    redirect: _91category_93S43E2l0ypAMeta?.redirect,
    component: () => import("/app/apps/site/pages/faq/[category].vue").then(m => m.default || m)
  },
  {
    name: _91category_93S43E2l0ypAMeta?.name ?? "faq-category___mk",
    path: _91category_93S43E2l0ypAMeta?.path ?? "/mk/cesta-pitanja/:category()",
    meta: _91category_93S43E2l0ypAMeta || {},
    alias: _91category_93S43E2l0ypAMeta?.alias || [],
    redirect: _91category_93S43E2l0ypAMeta?.redirect,
    component: () => import("/app/apps/site/pages/faq/[category].vue").then(m => m.default || m)
  },
  {
    name: indexyh7btIRDb0Meta?.name ?? "faq___hr",
    path: indexyh7btIRDb0Meta?.path ?? "/cesta-pitanja",
    meta: indexyh7btIRDb0Meta || {},
    alias: indexyh7btIRDb0Meta?.alias || [],
    redirect: indexyh7btIRDb0Meta?.redirect,
    component: () => import("/app/apps/site/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexyh7btIRDb0Meta?.name ?? "faq___en",
    path: indexyh7btIRDb0Meta?.path ?? "/en/faq",
    meta: indexyh7btIRDb0Meta || {},
    alias: indexyh7btIRDb0Meta?.alias || [],
    redirect: indexyh7btIRDb0Meta?.redirect,
    component: () => import("/app/apps/site/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexyh7btIRDb0Meta?.name ?? "faq___sl",
    path: indexyh7btIRDb0Meta?.path ?? "/sl/cesta-pitanja",
    meta: indexyh7btIRDb0Meta || {},
    alias: indexyh7btIRDb0Meta?.alias || [],
    redirect: indexyh7btIRDb0Meta?.redirect,
    component: () => import("/app/apps/site/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexyh7btIRDb0Meta?.name ?? "faq___bs",
    path: indexyh7btIRDb0Meta?.path ?? "/bs/cesta-pitanja",
    meta: indexyh7btIRDb0Meta || {},
    alias: indexyh7btIRDb0Meta?.alias || [],
    redirect: indexyh7btIRDb0Meta?.redirect,
    component: () => import("/app/apps/site/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexyh7btIRDb0Meta?.name ?? "faq___mk",
    path: indexyh7btIRDb0Meta?.path ?? "/mk/cesta-pitanja",
    meta: indexyh7btIRDb0Meta || {},
    alias: indexyh7btIRDb0Meta?.alias || [],
    redirect: indexyh7btIRDb0Meta?.redirect,
    component: () => import("/app/apps/site/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexhfvhpQJmdsMeta?.name ?? "index___hr",
    path: indexhfvhpQJmdsMeta?.path ?? "/",
    meta: indexhfvhpQJmdsMeta || {},
    alias: indexhfvhpQJmdsMeta?.alias || [],
    redirect: indexhfvhpQJmdsMeta?.redirect,
    component: () => import("/app/apps/site/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexhfvhpQJmdsMeta?.name ?? "index___en",
    path: indexhfvhpQJmdsMeta?.path ?? "/en",
    meta: indexhfvhpQJmdsMeta || {},
    alias: indexhfvhpQJmdsMeta?.alias || [],
    redirect: indexhfvhpQJmdsMeta?.redirect,
    component: () => import("/app/apps/site/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexhfvhpQJmdsMeta?.name ?? "index___sl",
    path: indexhfvhpQJmdsMeta?.path ?? "/sl",
    meta: indexhfvhpQJmdsMeta || {},
    alias: indexhfvhpQJmdsMeta?.alias || [],
    redirect: indexhfvhpQJmdsMeta?.redirect,
    component: () => import("/app/apps/site/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexhfvhpQJmdsMeta?.name ?? "index___bs",
    path: indexhfvhpQJmdsMeta?.path ?? "/bs",
    meta: indexhfvhpQJmdsMeta || {},
    alias: indexhfvhpQJmdsMeta?.alias || [],
    redirect: indexhfvhpQJmdsMeta?.redirect,
    component: () => import("/app/apps/site/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexhfvhpQJmdsMeta?.name ?? "index___mk",
    path: indexhfvhpQJmdsMeta?.path ?? "/mk",
    meta: indexhfvhpQJmdsMeta || {},
    alias: indexhfvhpQJmdsMeta?.alias || [],
    redirect: indexhfvhpQJmdsMeta?.redirect,
    component: () => import("/app/apps/site/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUsuQsVLwcMeta?.name ?? "info-slug___hr",
    path: _91slug_93WUsuQsVLwcMeta?.path ?? "/info/:slug()",
    meta: _91slug_93WUsuQsVLwcMeta || {},
    alias: _91slug_93WUsuQsVLwcMeta?.alias || [],
    redirect: _91slug_93WUsuQsVLwcMeta?.redirect,
    component: () => import("/app/apps/site/pages/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUsuQsVLwcMeta?.name ?? "info-slug___en",
    path: _91slug_93WUsuQsVLwcMeta?.path ?? "/en/info/:slug()",
    meta: _91slug_93WUsuQsVLwcMeta || {},
    alias: _91slug_93WUsuQsVLwcMeta?.alias || [],
    redirect: _91slug_93WUsuQsVLwcMeta?.redirect,
    component: () => import("/app/apps/site/pages/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUsuQsVLwcMeta?.name ?? "info-slug___sl",
    path: _91slug_93WUsuQsVLwcMeta?.path ?? "/sl/info/:slug()",
    meta: _91slug_93WUsuQsVLwcMeta || {},
    alias: _91slug_93WUsuQsVLwcMeta?.alias || [],
    redirect: _91slug_93WUsuQsVLwcMeta?.redirect,
    component: () => import("/app/apps/site/pages/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUsuQsVLwcMeta?.name ?? "info-slug___bs",
    path: _91slug_93WUsuQsVLwcMeta?.path ?? "/bs/info/:slug()",
    meta: _91slug_93WUsuQsVLwcMeta || {},
    alias: _91slug_93WUsuQsVLwcMeta?.alias || [],
    redirect: _91slug_93WUsuQsVLwcMeta?.redirect,
    component: () => import("/app/apps/site/pages/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WUsuQsVLwcMeta?.name ?? "info-slug___mk",
    path: _91slug_93WUsuQsVLwcMeta?.path ?? "/mk/info/:slug()",
    meta: _91slug_93WUsuQsVLwcMeta || {},
    alias: _91slug_93WUsuQsVLwcMeta?.alias || [],
    redirect: _91slug_93WUsuQsVLwcMeta?.redirect,
    component: () => import("/app/apps/site/pages/info/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91jobId_93r9pWVUJYhIMeta?.name ?? "job-jobId___hr",
    path: _91jobId_93r9pWVUJYhIMeta?.path ?? "/posao/:jobId()",
    meta: _91jobId_93r9pWVUJYhIMeta || {},
    alias: _91jobId_93r9pWVUJYhIMeta?.alias || [],
    redirect: _91jobId_93r9pWVUJYhIMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue").then(m => m.default || m),
    children: [
  {
    name: apply_45external_45countdowno4dScsCepgMeta?.name ?? "job-jobId-slug-apply-external-countdown___hr",
    path: apply_45external_45countdowno4dScsCepgMeta?.path ?? ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    alias: apply_45external_45countdowno4dScsCepgMeta?.alias || [],
    redirect: apply_45external_45countdowno4dScsCepgMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue").then(m => m.default || m)
  },
  {
    name: apply_45external_45formffRxqQBZErMeta?.name ?? "job-jobId-slug-apply-external-form___hr",
    path: apply_45external_45formffRxqQBZErMeta?.path ?? ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    alias: apply_45external_45formffRxqQBZErMeta?.alias || [],
    redirect: apply_45external_45formffRxqQBZErMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue").then(m => m.default || m)
  },
  {
    name: apply_45externalZSWdh18JwnMeta?.name ?? "job-jobId-slug-apply-external___hr",
    path: apply_45externalZSWdh18JwnMeta?.path ?? ":slug()/apply-external",
    meta: apply_45externalZSWdh18JwnMeta || {},
    alias: apply_45externalZSWdh18JwnMeta?.alias || [],
    redirect: apply_45externalZSWdh18JwnMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue").then(m => m.default || m)
  },
  {
    name: applyckcqptWrm5Meta?.name ?? "job-jobId-slug-apply___hr",
    path: applyckcqptWrm5Meta?.path ?? ":slug()/prijava",
    meta: applyckcqptWrm5Meta || {},
    alias: applyckcqptWrm5Meta?.alias || [],
    redirect: applyckcqptWrm5Meta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue").then(m => m.default || m)
  },
  {
    name: detailsUwtPC3FJB9Meta?.name ?? "job-jobId-slug-details___hr",
    path: detailsUwtPC3FJB9Meta?.path ?? ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    alias: detailsUwtPC3FJB9Meta?.alias || [],
    redirect: detailsUwtPC3FJB9Meta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91jobId_93r9pWVUJYhIMeta?.name ?? "job-jobId___en",
    path: _91jobId_93r9pWVUJYhIMeta?.path ?? "/en/job/:jobId()",
    meta: _91jobId_93r9pWVUJYhIMeta || {},
    alias: _91jobId_93r9pWVUJYhIMeta?.alias || [],
    redirect: _91jobId_93r9pWVUJYhIMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue").then(m => m.default || m),
    children: [
  {
    name: apply_45external_45countdowno4dScsCepgMeta?.name ?? "job-jobId-slug-apply-external-countdown___en",
    path: apply_45external_45countdowno4dScsCepgMeta?.path ?? ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    alias: apply_45external_45countdowno4dScsCepgMeta?.alias || [],
    redirect: apply_45external_45countdowno4dScsCepgMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue").then(m => m.default || m)
  },
  {
    name: apply_45external_45formffRxqQBZErMeta?.name ?? "job-jobId-slug-apply-external-form___en",
    path: apply_45external_45formffRxqQBZErMeta?.path ?? ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    alias: apply_45external_45formffRxqQBZErMeta?.alias || [],
    redirect: apply_45external_45formffRxqQBZErMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue").then(m => m.default || m)
  },
  {
    name: apply_45externalZSWdh18JwnMeta?.name ?? "job-jobId-slug-apply-external___en",
    path: apply_45externalZSWdh18JwnMeta?.path ?? ":slug()/apply-external",
    meta: apply_45externalZSWdh18JwnMeta || {},
    alias: apply_45externalZSWdh18JwnMeta?.alias || [],
    redirect: apply_45externalZSWdh18JwnMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue").then(m => m.default || m)
  },
  {
    name: applyckcqptWrm5Meta?.name ?? "job-jobId-slug-apply___en",
    path: applyckcqptWrm5Meta?.path ?? ":slug()/apply",
    meta: applyckcqptWrm5Meta || {},
    alias: applyckcqptWrm5Meta?.alias || [],
    redirect: applyckcqptWrm5Meta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue").then(m => m.default || m)
  },
  {
    name: detailsUwtPC3FJB9Meta?.name ?? "job-jobId-slug-details___en",
    path: detailsUwtPC3FJB9Meta?.path ?? ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    alias: detailsUwtPC3FJB9Meta?.alias || [],
    redirect: detailsUwtPC3FJB9Meta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91jobId_93r9pWVUJYhIMeta?.name ?? "job-jobId___sl",
    path: _91jobId_93r9pWVUJYhIMeta?.path ?? "/sl/posao/:jobId()",
    meta: _91jobId_93r9pWVUJYhIMeta || {},
    alias: _91jobId_93r9pWVUJYhIMeta?.alias || [],
    redirect: _91jobId_93r9pWVUJYhIMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue").then(m => m.default || m),
    children: [
  {
    name: apply_45external_45countdowno4dScsCepgMeta?.name ?? "job-jobId-slug-apply-external-countdown___sl",
    path: apply_45external_45countdowno4dScsCepgMeta?.path ?? ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    alias: apply_45external_45countdowno4dScsCepgMeta?.alias || [],
    redirect: apply_45external_45countdowno4dScsCepgMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue").then(m => m.default || m)
  },
  {
    name: apply_45external_45formffRxqQBZErMeta?.name ?? "job-jobId-slug-apply-external-form___sl",
    path: apply_45external_45formffRxqQBZErMeta?.path ?? ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    alias: apply_45external_45formffRxqQBZErMeta?.alias || [],
    redirect: apply_45external_45formffRxqQBZErMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue").then(m => m.default || m)
  },
  {
    name: apply_45externalZSWdh18JwnMeta?.name ?? "job-jobId-slug-apply-external___sl",
    path: apply_45externalZSWdh18JwnMeta?.path ?? ":slug()/apply-external",
    meta: apply_45externalZSWdh18JwnMeta || {},
    alias: apply_45externalZSWdh18JwnMeta?.alias || [],
    redirect: apply_45externalZSWdh18JwnMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue").then(m => m.default || m)
  },
  {
    name: applyckcqptWrm5Meta?.name ?? "job-jobId-slug-apply___sl",
    path: applyckcqptWrm5Meta?.path ?? ":slug()/prijava",
    meta: applyckcqptWrm5Meta || {},
    alias: applyckcqptWrm5Meta?.alias || [],
    redirect: applyckcqptWrm5Meta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue").then(m => m.default || m)
  },
  {
    name: detailsUwtPC3FJB9Meta?.name ?? "job-jobId-slug-details___sl",
    path: detailsUwtPC3FJB9Meta?.path ?? ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    alias: detailsUwtPC3FJB9Meta?.alias || [],
    redirect: detailsUwtPC3FJB9Meta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91jobId_93r9pWVUJYhIMeta?.name ?? "job-jobId___bs",
    path: _91jobId_93r9pWVUJYhIMeta?.path ?? "/bs/posao/:jobId()",
    meta: _91jobId_93r9pWVUJYhIMeta || {},
    alias: _91jobId_93r9pWVUJYhIMeta?.alias || [],
    redirect: _91jobId_93r9pWVUJYhIMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue").then(m => m.default || m),
    children: [
  {
    name: apply_45external_45countdowno4dScsCepgMeta?.name ?? "job-jobId-slug-apply-external-countdown___bs",
    path: apply_45external_45countdowno4dScsCepgMeta?.path ?? ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    alias: apply_45external_45countdowno4dScsCepgMeta?.alias || [],
    redirect: apply_45external_45countdowno4dScsCepgMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue").then(m => m.default || m)
  },
  {
    name: apply_45external_45formffRxqQBZErMeta?.name ?? "job-jobId-slug-apply-external-form___bs",
    path: apply_45external_45formffRxqQBZErMeta?.path ?? ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    alias: apply_45external_45formffRxqQBZErMeta?.alias || [],
    redirect: apply_45external_45formffRxqQBZErMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue").then(m => m.default || m)
  },
  {
    name: apply_45externalZSWdh18JwnMeta?.name ?? "job-jobId-slug-apply-external___bs",
    path: apply_45externalZSWdh18JwnMeta?.path ?? ":slug()/apply-external",
    meta: apply_45externalZSWdh18JwnMeta || {},
    alias: apply_45externalZSWdh18JwnMeta?.alias || [],
    redirect: apply_45externalZSWdh18JwnMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue").then(m => m.default || m)
  },
  {
    name: applyckcqptWrm5Meta?.name ?? "job-jobId-slug-apply___bs",
    path: applyckcqptWrm5Meta?.path ?? ":slug()/prijava",
    meta: applyckcqptWrm5Meta || {},
    alias: applyckcqptWrm5Meta?.alias || [],
    redirect: applyckcqptWrm5Meta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue").then(m => m.default || m)
  },
  {
    name: detailsUwtPC3FJB9Meta?.name ?? "job-jobId-slug-details___bs",
    path: detailsUwtPC3FJB9Meta?.path ?? ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    alias: detailsUwtPC3FJB9Meta?.alias || [],
    redirect: detailsUwtPC3FJB9Meta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91jobId_93r9pWVUJYhIMeta?.name ?? "job-jobId___mk",
    path: _91jobId_93r9pWVUJYhIMeta?.path ?? "/mk/posao/:jobId()",
    meta: _91jobId_93r9pWVUJYhIMeta || {},
    alias: _91jobId_93r9pWVUJYhIMeta?.alias || [],
    redirect: _91jobId_93r9pWVUJYhIMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue").then(m => m.default || m),
    children: [
  {
    name: apply_45external_45countdowno4dScsCepgMeta?.name ?? "job-jobId-slug-apply-external-countdown___mk",
    path: apply_45external_45countdowno4dScsCepgMeta?.path ?? ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    alias: apply_45external_45countdowno4dScsCepgMeta?.alias || [],
    redirect: apply_45external_45countdowno4dScsCepgMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue").then(m => m.default || m)
  },
  {
    name: apply_45external_45formffRxqQBZErMeta?.name ?? "job-jobId-slug-apply-external-form___mk",
    path: apply_45external_45formffRxqQBZErMeta?.path ?? ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    alias: apply_45external_45formffRxqQBZErMeta?.alias || [],
    redirect: apply_45external_45formffRxqQBZErMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue").then(m => m.default || m)
  },
  {
    name: apply_45externalZSWdh18JwnMeta?.name ?? "job-jobId-slug-apply-external___mk",
    path: apply_45externalZSWdh18JwnMeta?.path ?? ":slug()/apply-external",
    meta: apply_45externalZSWdh18JwnMeta || {},
    alias: apply_45externalZSWdh18JwnMeta?.alias || [],
    redirect: apply_45externalZSWdh18JwnMeta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue").then(m => m.default || m)
  },
  {
    name: applyckcqptWrm5Meta?.name ?? "job-jobId-slug-apply___mk",
    path: applyckcqptWrm5Meta?.path ?? ":slug()/prijava",
    meta: applyckcqptWrm5Meta || {},
    alias: applyckcqptWrm5Meta?.alias || [],
    redirect: applyckcqptWrm5Meta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue").then(m => m.default || m)
  },
  {
    name: detailsUwtPC3FJB9Meta?.name ?? "job-jobId-slug-details___mk",
    path: detailsUwtPC3FJB9Meta?.path ?? ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    alias: detailsUwtPC3FJB9Meta?.alias || [],
    redirect: detailsUwtPC3FJB9Meta?.redirect,
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91slug_930wDRPfkTTpMeta?.name ?? "jobs-slug___hr",
    path: _91slug_930wDRPfkTTpMeta?.path ?? "/poslovi/:slug()",
    meta: _91slug_930wDRPfkTTpMeta || {},
    alias: _91slug_930wDRPfkTTpMeta?.alias || [],
    redirect: _91slug_930wDRPfkTTpMeta?.redirect,
    component: () => import("/app/apps/jobs/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930wDRPfkTTpMeta?.name ?? "jobs-slug___en",
    path: _91slug_930wDRPfkTTpMeta?.path ?? "/en/jobs/:slug()",
    meta: _91slug_930wDRPfkTTpMeta || {},
    alias: _91slug_930wDRPfkTTpMeta?.alias || [],
    redirect: _91slug_930wDRPfkTTpMeta?.redirect,
    component: () => import("/app/apps/jobs/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930wDRPfkTTpMeta?.name ?? "jobs-slug___sl",
    path: _91slug_930wDRPfkTTpMeta?.path ?? "/sl/poslovi/:slug()",
    meta: _91slug_930wDRPfkTTpMeta || {},
    alias: _91slug_930wDRPfkTTpMeta?.alias || [],
    redirect: _91slug_930wDRPfkTTpMeta?.redirect,
    component: () => import("/app/apps/jobs/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930wDRPfkTTpMeta?.name ?? "jobs-slug___bs",
    path: _91slug_930wDRPfkTTpMeta?.path ?? "/bs/poslovi/:slug()",
    meta: _91slug_930wDRPfkTTpMeta || {},
    alias: _91slug_930wDRPfkTTpMeta?.alias || [],
    redirect: _91slug_930wDRPfkTTpMeta?.redirect,
    component: () => import("/app/apps/jobs/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930wDRPfkTTpMeta?.name ?? "jobs-slug___mk",
    path: _91slug_930wDRPfkTTpMeta?.path ?? "/mk/poslovi/:slug()",
    meta: _91slug_930wDRPfkTTpMeta || {},
    alias: _91slug_930wDRPfkTTpMeta?.alias || [],
    redirect: _91slug_930wDRPfkTTpMeta?.redirect,
    component: () => import("/app/apps/jobs/pages/jobs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMZAUHAfz2hMeta?.name ?? "jobs___hr",
    path: indexMZAUHAfz2hMeta?.path ?? "/poslovi",
    meta: indexMZAUHAfz2hMeta || {},
    alias: indexMZAUHAfz2hMeta?.alias || [],
    redirect: indexMZAUHAfz2hMeta?.redirect,
    component: () => import("/app/apps/jobs/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMZAUHAfz2hMeta?.name ?? "jobs___en",
    path: indexMZAUHAfz2hMeta?.path ?? "/en/jobs",
    meta: indexMZAUHAfz2hMeta || {},
    alias: indexMZAUHAfz2hMeta?.alias || [],
    redirect: indexMZAUHAfz2hMeta?.redirect,
    component: () => import("/app/apps/jobs/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMZAUHAfz2hMeta?.name ?? "jobs___sl",
    path: indexMZAUHAfz2hMeta?.path ?? "/sl/poslovi",
    meta: indexMZAUHAfz2hMeta || {},
    alias: indexMZAUHAfz2hMeta?.alias || [],
    redirect: indexMZAUHAfz2hMeta?.redirect,
    component: () => import("/app/apps/jobs/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMZAUHAfz2hMeta?.name ?? "jobs___bs",
    path: indexMZAUHAfz2hMeta?.path ?? "/bs/poslovi",
    meta: indexMZAUHAfz2hMeta || {},
    alias: indexMZAUHAfz2hMeta?.alias || [],
    redirect: indexMZAUHAfz2hMeta?.redirect,
    component: () => import("/app/apps/jobs/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexMZAUHAfz2hMeta?.name ?? "jobs___mk",
    path: indexMZAUHAfz2hMeta?.path ?? "/mk/poslovi",
    meta: indexMZAUHAfz2hMeta || {},
    alias: indexMZAUHAfz2hMeta?.alias || [],
    redirect: indexMZAUHAfz2hMeta?.redirect,
    component: () => import("/app/apps/jobs/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: my_45profile_45preview09icPF9X4kMeta?.name ?? "my-profile-preview___hr",
    path: my_45profile_45preview09icPF9X4kMeta?.path ?? "/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    alias: my_45profile_45preview09icPF9X4kMeta?.alias || [],
    redirect: my_45profile_45preview09icPF9X4kMeta?.redirect,
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue").then(m => m.default || m)
  },
  {
    name: my_45profile_45preview09icPF9X4kMeta?.name ?? "my-profile-preview___en",
    path: my_45profile_45preview09icPF9X4kMeta?.path ?? "/en/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    alias: my_45profile_45preview09icPF9X4kMeta?.alias || [],
    redirect: my_45profile_45preview09icPF9X4kMeta?.redirect,
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue").then(m => m.default || m)
  },
  {
    name: my_45profile_45preview09icPF9X4kMeta?.name ?? "my-profile-preview___sl",
    path: my_45profile_45preview09icPF9X4kMeta?.path ?? "/sl/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    alias: my_45profile_45preview09icPF9X4kMeta?.alias || [],
    redirect: my_45profile_45preview09icPF9X4kMeta?.redirect,
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue").then(m => m.default || m)
  },
  {
    name: my_45profile_45preview09icPF9X4kMeta?.name ?? "my-profile-preview___bs",
    path: my_45profile_45preview09icPF9X4kMeta?.path ?? "/bs/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    alias: my_45profile_45preview09icPF9X4kMeta?.alias || [],
    redirect: my_45profile_45preview09icPF9X4kMeta?.redirect,
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue").then(m => m.default || m)
  },
  {
    name: my_45profile_45preview09icPF9X4kMeta?.name ?? "my-profile-preview___mk",
    path: my_45profile_45preview09icPF9X4kMeta?.path ?? "/mk/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    alias: my_45profile_45preview09icPF9X4kMeta?.alias || [],
    redirect: my_45profile_45preview09icPF9X4kMeta?.redirect,
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue").then(m => m.default || m)
  },
  {
    name: my_45profileOqnUgpRMHiMeta?.name ?? "my-profile___hr",
    path: my_45profileOqnUgpRMHiMeta?.path ?? "/moj-profil",
    meta: my_45profileOqnUgpRMHiMeta || {},
    alias: my_45profileOqnUgpRMHiMeta?.alias || [],
    redirect: my_45profileOqnUgpRMHiMeta?.redirect,
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue").then(m => m.default || m)
  },
  {
    name: my_45profileOqnUgpRMHiMeta?.name ?? "my-profile___en",
    path: my_45profileOqnUgpRMHiMeta?.path ?? "/en/my-profile",
    meta: my_45profileOqnUgpRMHiMeta || {},
    alias: my_45profileOqnUgpRMHiMeta?.alias || [],
    redirect: my_45profileOqnUgpRMHiMeta?.redirect,
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue").then(m => m.default || m)
  },
  {
    name: my_45profileOqnUgpRMHiMeta?.name ?? "my-profile___sl",
    path: my_45profileOqnUgpRMHiMeta?.path ?? "/sl/moj-profil",
    meta: my_45profileOqnUgpRMHiMeta || {},
    alias: my_45profileOqnUgpRMHiMeta?.alias || [],
    redirect: my_45profileOqnUgpRMHiMeta?.redirect,
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue").then(m => m.default || m)
  },
  {
    name: my_45profileOqnUgpRMHiMeta?.name ?? "my-profile___bs",
    path: my_45profileOqnUgpRMHiMeta?.path ?? "/bs/moj-profil",
    meta: my_45profileOqnUgpRMHiMeta || {},
    alias: my_45profileOqnUgpRMHiMeta?.alias || [],
    redirect: my_45profileOqnUgpRMHiMeta?.redirect,
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue").then(m => m.default || m)
  },
  {
    name: my_45profileOqnUgpRMHiMeta?.name ?? "my-profile___mk",
    path: my_45profileOqnUgpRMHiMeta?.path ?? "/mk/moj-profil",
    meta: my_45profileOqnUgpRMHiMeta || {},
    alias: my_45profileOqnUgpRMHiMeta?.alias || [],
    redirect: my_45profileOqnUgpRMHiMeta?.redirect,
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue").then(m => m.default || m)
  },
  {
    name: applicationsSeLeQ1SiZOMeta?.name ?? "my-applications___hr",
    path: applicationsSeLeQ1SiZOMeta?.path ?? "/moj-profil/prijave",
    meta: applicationsSeLeQ1SiZOMeta || {},
    alias: applicationsSeLeQ1SiZOMeta?.alias || [],
    redirect: applicationsSeLeQ1SiZOMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/applications.vue").then(m => m.default || m)
  },
  {
    name: applicationsSeLeQ1SiZOMeta?.name ?? "my-applications___en",
    path: applicationsSeLeQ1SiZOMeta?.path ?? "/en/my-profile/applications",
    meta: applicationsSeLeQ1SiZOMeta || {},
    alias: applicationsSeLeQ1SiZOMeta?.alias || [],
    redirect: applicationsSeLeQ1SiZOMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/applications.vue").then(m => m.default || m)
  },
  {
    name: applicationsSeLeQ1SiZOMeta?.name ?? "my-applications___sl",
    path: applicationsSeLeQ1SiZOMeta?.path ?? "/sl/moj-profil/prijave",
    meta: applicationsSeLeQ1SiZOMeta || {},
    alias: applicationsSeLeQ1SiZOMeta?.alias || [],
    redirect: applicationsSeLeQ1SiZOMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/applications.vue").then(m => m.default || m)
  },
  {
    name: applicationsSeLeQ1SiZOMeta?.name ?? "my-applications___bs",
    path: applicationsSeLeQ1SiZOMeta?.path ?? "/bs/moj-profil/prijave",
    meta: applicationsSeLeQ1SiZOMeta || {},
    alias: applicationsSeLeQ1SiZOMeta?.alias || [],
    redirect: applicationsSeLeQ1SiZOMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/applications.vue").then(m => m.default || m)
  },
  {
    name: applicationsSeLeQ1SiZOMeta?.name ?? "my-applications___mk",
    path: applicationsSeLeQ1SiZOMeta?.path ?? "/mk/moj-profil/prijave",
    meta: applicationsSeLeQ1SiZOMeta || {},
    alias: applicationsSeLeQ1SiZOMeta?.alias || [],
    redirect: applicationsSeLeQ1SiZOMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/applications.vue").then(m => m.default || m)
  },
  {
    name: documentspLgNA6kZOSMeta?.name ?? "my-documents___hr",
    path: documentspLgNA6kZOSMeta?.path ?? "/moj-profil/dokumenti",
    meta: documentspLgNA6kZOSMeta || {},
    alias: documentspLgNA6kZOSMeta?.alias || [],
    redirect: documentspLgNA6kZOSMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/documents.vue").then(m => m.default || m)
  },
  {
    name: documentspLgNA6kZOSMeta?.name ?? "my-documents___en",
    path: documentspLgNA6kZOSMeta?.path ?? "/en/my-profile/documents",
    meta: documentspLgNA6kZOSMeta || {},
    alias: documentspLgNA6kZOSMeta?.alias || [],
    redirect: documentspLgNA6kZOSMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/documents.vue").then(m => m.default || m)
  },
  {
    name: documentspLgNA6kZOSMeta?.name ?? "my-documents___sl",
    path: documentspLgNA6kZOSMeta?.path ?? "/sl/moj-profil/dokumenti",
    meta: documentspLgNA6kZOSMeta || {},
    alias: documentspLgNA6kZOSMeta?.alias || [],
    redirect: documentspLgNA6kZOSMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/documents.vue").then(m => m.default || m)
  },
  {
    name: documentspLgNA6kZOSMeta?.name ?? "my-documents___bs",
    path: documentspLgNA6kZOSMeta?.path ?? "/bs/moj-profil/dokumenti",
    meta: documentspLgNA6kZOSMeta || {},
    alias: documentspLgNA6kZOSMeta?.alias || [],
    redirect: documentspLgNA6kZOSMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/documents.vue").then(m => m.default || m)
  },
  {
    name: documentspLgNA6kZOSMeta?.name ?? "my-documents___mk",
    path: documentspLgNA6kZOSMeta?.path ?? "/mk/moj-profil/dokumenti",
    meta: documentspLgNA6kZOSMeta || {},
    alias: documentspLgNA6kZOSMeta?.alias || [],
    redirect: documentspLgNA6kZOSMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/documents.vue").then(m => m.default || m)
  },
  {
    name: favorite_45jobstsEDq5mqXaMeta?.name ?? "my-favorite-jobs___hr",
    path: favorite_45jobstsEDq5mqXaMeta?.path ?? "/moj-profil/spremljeni-poslovi",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    alias: favorite_45jobstsEDq5mqXaMeta?.alias || [],
    redirect: favorite_45jobstsEDq5mqXaMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue").then(m => m.default || m)
  },
  {
    name: favorite_45jobstsEDq5mqXaMeta?.name ?? "my-favorite-jobs___en",
    path: favorite_45jobstsEDq5mqXaMeta?.path ?? "/en/my-profile/saved-jobs",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    alias: favorite_45jobstsEDq5mqXaMeta?.alias || [],
    redirect: favorite_45jobstsEDq5mqXaMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue").then(m => m.default || m)
  },
  {
    name: favorite_45jobstsEDq5mqXaMeta?.name ?? "my-favorite-jobs___sl",
    path: favorite_45jobstsEDq5mqXaMeta?.path ?? "/sl/moj-profil/spremljeni-poslovi",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    alias: favorite_45jobstsEDq5mqXaMeta?.alias || [],
    redirect: favorite_45jobstsEDq5mqXaMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue").then(m => m.default || m)
  },
  {
    name: favorite_45jobstsEDq5mqXaMeta?.name ?? "my-favorite-jobs___bs",
    path: favorite_45jobstsEDq5mqXaMeta?.path ?? "/bs/moj-profil/spremljeni-poslovi",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    alias: favorite_45jobstsEDq5mqXaMeta?.alias || [],
    redirect: favorite_45jobstsEDq5mqXaMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue").then(m => m.default || m)
  },
  {
    name: favorite_45jobstsEDq5mqXaMeta?.name ?? "my-favorite-jobs___mk",
    path: favorite_45jobstsEDq5mqXaMeta?.path ?? "/mk/moj-profil/spremljeni-poslovi",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    alias: favorite_45jobstsEDq5mqXaMeta?.alias || [],
    redirect: favorite_45jobstsEDq5mqXaMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue").then(m => m.default || m)
  },
  {
    name: notificationsCc6VMGb4P0Meta?.name ?? "my-notifications___hr",
    path: notificationsCc6VMGb4P0Meta?.path ?? "/moj-profil/obavijesti",
    meta: notificationsCc6VMGb4P0Meta || {},
    alias: notificationsCc6VMGb4P0Meta?.alias || [],
    redirect: notificationsCc6VMGb4P0Meta?.redirect,
    component: () => import("/app/apps/site/pages/my/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsCc6VMGb4P0Meta?.name ?? "my-notifications___en",
    path: notificationsCc6VMGb4P0Meta?.path ?? "/en/my-profile/notifications",
    meta: notificationsCc6VMGb4P0Meta || {},
    alias: notificationsCc6VMGb4P0Meta?.alias || [],
    redirect: notificationsCc6VMGb4P0Meta?.redirect,
    component: () => import("/app/apps/site/pages/my/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsCc6VMGb4P0Meta?.name ?? "my-notifications___sl",
    path: notificationsCc6VMGb4P0Meta?.path ?? "/sl/moj-profil/obavijesti",
    meta: notificationsCc6VMGb4P0Meta || {},
    alias: notificationsCc6VMGb4P0Meta?.alias || [],
    redirect: notificationsCc6VMGb4P0Meta?.redirect,
    component: () => import("/app/apps/site/pages/my/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsCc6VMGb4P0Meta?.name ?? "my-notifications___bs",
    path: notificationsCc6VMGb4P0Meta?.path ?? "/bs/moj-profil/obavijesti",
    meta: notificationsCc6VMGb4P0Meta || {},
    alias: notificationsCc6VMGb4P0Meta?.alias || [],
    redirect: notificationsCc6VMGb4P0Meta?.redirect,
    component: () => import("/app/apps/site/pages/my/notifications.vue").then(m => m.default || m)
  },
  {
    name: notificationsCc6VMGb4P0Meta?.name ?? "my-notifications___mk",
    path: notificationsCc6VMGb4P0Meta?.path ?? "/mk/moj-profil/obavijesti",
    meta: notificationsCc6VMGb4P0Meta || {},
    alias: notificationsCc6VMGb4P0Meta?.alias || [],
    redirect: notificationsCc6VMGb4P0Meta?.redirect,
    component: () => import("/app/apps/site/pages/my/notifications.vue").then(m => m.default || m)
  },
  {
    name: settingsLPMr7BiiGHMeta?.name ?? "my-settings___hr",
    path: settingsLPMr7BiiGHMeta?.path ?? "/moj-profil/postavke",
    meta: settingsLPMr7BiiGHMeta || {},
    alias: settingsLPMr7BiiGHMeta?.alias || [],
    redirect: settingsLPMr7BiiGHMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsLPMr7BiiGHMeta?.name ?? "my-settings___en",
    path: settingsLPMr7BiiGHMeta?.path ?? "/en/my-profile/settings",
    meta: settingsLPMr7BiiGHMeta || {},
    alias: settingsLPMr7BiiGHMeta?.alias || [],
    redirect: settingsLPMr7BiiGHMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsLPMr7BiiGHMeta?.name ?? "my-settings___sl",
    path: settingsLPMr7BiiGHMeta?.path ?? "/sl/moj-profil/postavke",
    meta: settingsLPMr7BiiGHMeta || {},
    alias: settingsLPMr7BiiGHMeta?.alias || [],
    redirect: settingsLPMr7BiiGHMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsLPMr7BiiGHMeta?.name ?? "my-settings___bs",
    path: settingsLPMr7BiiGHMeta?.path ?? "/bs/moj-profil/postavke",
    meta: settingsLPMr7BiiGHMeta || {},
    alias: settingsLPMr7BiiGHMeta?.alias || [],
    redirect: settingsLPMr7BiiGHMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/settings.vue").then(m => m.default || m)
  },
  {
    name: settingsLPMr7BiiGHMeta?.name ?? "my-settings___mk",
    path: settingsLPMr7BiiGHMeta?.path ?? "/mk/moj-profil/postavke",
    meta: settingsLPMr7BiiGHMeta || {},
    alias: settingsLPMr7BiiGHMeta?.alias || [],
    redirect: settingsLPMr7BiiGHMeta?.redirect,
    component: () => import("/app/apps/site/pages/my/settings.vue").then(m => m.default || m)
  },
  {
    name: indexFlTcufSab2Meta?.name ?? "search___hr",
    path: indexFlTcufSab2Meta?.path ?? "/pretraga-poslova",
    meta: indexFlTcufSab2Meta || {},
    alias: indexFlTcufSab2Meta?.alias || [],
    redirect: indexFlTcufSab2Meta?.redirect,
    component: () => import("/app/apps/site/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexFlTcufSab2Meta?.name ?? "search___en",
    path: indexFlTcufSab2Meta?.path ?? "/en/job-search",
    meta: indexFlTcufSab2Meta || {},
    alias: indexFlTcufSab2Meta?.alias || [],
    redirect: indexFlTcufSab2Meta?.redirect,
    component: () => import("/app/apps/site/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexFlTcufSab2Meta?.name ?? "search___sl",
    path: indexFlTcufSab2Meta?.path ?? "/sl/pretraga-poslova",
    meta: indexFlTcufSab2Meta || {},
    alias: indexFlTcufSab2Meta?.alias || [],
    redirect: indexFlTcufSab2Meta?.redirect,
    component: () => import("/app/apps/site/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexFlTcufSab2Meta?.name ?? "search___bs",
    path: indexFlTcufSab2Meta?.path ?? "/bs/pretraga-poslova",
    meta: indexFlTcufSab2Meta || {},
    alias: indexFlTcufSab2Meta?.alias || [],
    redirect: indexFlTcufSab2Meta?.redirect,
    component: () => import("/app/apps/site/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexFlTcufSab2Meta?.name ?? "search___mk",
    path: indexFlTcufSab2Meta?.path ?? "/mk/pretraga-poslova",
    meta: indexFlTcufSab2Meta || {},
    alias: indexFlTcufSab2Meta?.alias || [],
    redirect: indexFlTcufSab2Meta?.redirect,
    component: () => import("/app/apps/site/pages/search/index.vue").then(m => m.default || m)
  }
]