<script setup lang="ts">
  import { MpTabs, MpText } from '@borg/ui';
  import { injectAuthForm } from './useAuthForm';

  defineEmits<{
    (event: 'success'): void;
  }>();

  const authForm = injectAuthForm();
</script>

<template>
  <div>
    <LinksLocale
      v-if="authForm.config.showLogo"
      to="index"
      class="logo-link"
    >
      <Logo height="40" />
    </LinksLocale>
    <MpTabs
      v-if="authForm.config.tab === 'login' || authForm.config.tab === 'register'"
      :tabs="[$t('SIGN_UP_PAGE.LOGIN_TAB_LABEL'), $t('SIGN_UP_PAGE.REGISTER_TAB_LABEL')]"
      :model-value="authForm.config.tab === 'login' ? 0 : 1"
      data-test-prefix="auth-form"
      @change="(tab) => authForm.setTab(tab === 0 ? 'login' : 'register')"
    >
      <template #between>
        <MpText class="social-intro">{{ $t('SIGN_UP_PAGE.SOCIAL_LOGIN_INTRO') }}</MpText>
        <AuthPartsSocialLoginButtons @success="$emit('success')" />
        <AuthPartsFormSeparator />
      </template>
      <AuthFormLogin @success="$emit('success')" />
      <Register />
    </MpTabs>
    <AuthFormVerify
      v-else-if="authForm.config.tab === 'verify'"
      @success="$emit('success')"
    />
    <AuthFormForgottenPassword v-else-if="authForm.config.tab === 'forgotten_password'" />
    <AuthFormNewPassword v-else-if="authForm.config.tab === 'new_password'" />
  </div>
</template>

<style scoped lang="scss">
  .logo-link {
    display: block;
    margin-bottom: var(--mp-space-70);
  }

  .social-intro {
    display: block;
    width: 100%;
    text-align: center;
    color: var(--mp-color-grey-600);
    margin-bottom: var(--mp-space-50);
  }
</style>
